import { TrashIcon } from "@heroicons/react/24/solid";
import { Dialog } from "@headlessui/react";
import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";

import Dropzone from "../components/Dropzone";
import InputField from "../components/InputField";
import SwitchButton from "../components/SwitchButton";
import Divider from "../components/Divider";
import Button from "../components/buttons/Button";
import { getToken } from "../utils/token";
import { getFileNameFromUrl, toBase64 } from "../utils/files";
import DropdownMenu from "../components/DropdownMenu";
import { MenuItem, PlayerSize, FlooringType, Currency, TImage } from "../types";
import { getPlayerSizes } from "../api/playerSize";
import { deleteField } from "../api/fields";
import { useTranslation } from "react-i18next";
import { COMMON_TRANSLATE_KEYS } from "../constants/translate-keys";
import CurrencyDropdown from "../components/dropdowns/CurrencyDropdown";
import { getHeaders } from "../api/utils/getHeader";
import FlooringTypesDropdown from "../components/dropdowns/FlooringTypesDropdown";
import { yupResolver } from "@hookform/resolvers/yup";
import { addFieldSchema } from "../validation/schemas/field";

interface IFormInput {
  complexId: number,
  currencyId: number,
  sizeId: number,
  floorId: number,
  fieldName: string,
  pricePerHour: string,
  length: string,
  width: string,
}

const EditFieldInfo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currency, setCurrency] = useState<MenuItem<Currency> | null>(null);
  const [sizeOptions, setSizeOptions] = useState<Array<MenuItem<PlayerSize>>>(
    []
  );
  const [selectedSize, setSelectedSize] = useState<MenuItem<PlayerSize> | null>(
    null
  );
  const [selectedFloor, setSelectedFloor] =
    useState<MenuItem<FlooringType> | null>(null);
  const [isCovered, setIsCovered] = useState<boolean>(false);
  const [heating, setHeating] = useState<boolean>(false);
  const [lighting, setLighting] = useState<boolean>(false);
  const [tribunes, setTribunes] = useState<boolean>(false);
  const [isUnavailable, setIsUnavailable] = useState<boolean>(false);
  const [images, setImages] = useState<TImage[]>([]);

  // Images to remove on the server. Stores file names
  const [imagesToRemove, setImagesToRemove] = useState<string[]>([]);

  const {
    register,
    handleSubmit: handleFormSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<IFormInput>({
    defaultValues: {
      fieldName: '',
      complexId: 0,
      currencyId: 0,
      sizeId: 0,
      floorId: 0,
      pricePerHour: '',
      length: '',
      width: '',
    },
    resolver: yupResolver(addFieldSchema),
    mode: 'all',
  });

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { id, complexId } = useParams<{ id: string; complexId: string }>();

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const addImages = useCallback((newImages: TImage[]) => {
    setImages((prev) => [...prev, ...newImages]);
  }, []);

  const removeImage = useCallback((image: TImage) => {
    setImages((prev) => prev.filter((img) => img.id !== image.id));

    // If there is no file, that means that the image is uploaded on the server
    if (!image.file) {
      setImagesToRemove((prev) => [...prev, image.id]);
    }
  }, []);

  const handleCurrencySelect = useCallback((option: MenuItem<Currency>) => {
    setCurrency(option);
    setValue('currencyId', option.value.id, { shouldValidate: true });
  }, [setValue]);

  const handleSizeSelect = useCallback((option: MenuItem<PlayerSize>) => {
    setSelectedSize(option);
    setValue('sizeId', option.value.id, { shouldValidate: true });
  }, [setValue]);

  const handleFlooringSelect = useCallback((option: MenuItem<FlooringType>) => {
    setSelectedFloor(option);
    setValue('floorId', option.value.id, { shouldValidate: true });
  }, [setValue]);

  useEffect(() => {
    const loadPlayerSizes = async () => {
      const playerSizesData = await getPlayerSizes();
      setSizeOptions(
        playerSizesData.map((ps) => ({
          label: ps.label,
          value: ps,
          onClick: () => setSelectedSize({ label: ps.label, value: ps }),
        }))
      );
    };

    Promise.all([loadPlayerSizes()]);
  }, []);

  useEffect(() => {
    const fetchFieldDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/owner/complexes/${complexId}/fields/${id}`,
          { headers: { Authorization: `Bearer ${getToken()}` } }
        );
        const data = response.data;
        setCurrency({ label: data.currencyData.name, value: data.currencyData });
        setSelectedSize({
          label: data.playerSize.label,
          value: data.playerSize,
        });
        setSelectedFloor({
          label: data.flooringType.name,
          value: data.flooringType,
        });
        setIsCovered(data.isCovered);
        setHeating(data.heating);
        setLighting(data.lighting);
        setTribunes(data.tribunes);
        setIsUnavailable(data.isUnavailable);

        setValue("fieldName", data.name);
        setValue("complexId", data.complexId);
        setValue("currencyId", data.currencyData.id);
        setValue("sizeId", data.playerSize.id);
        setValue("floorId", data.flooringType.id);
        setValue("pricePerHour", data.pricePerHour);
        setValue("length", data.length);
        setValue("width", data.width);

        if (data.imageUrls) {
          const currentImages = data.imageUrls.map((src: string) => {
            const id = getFileNameFromUrl(src, 'field');

            return {
              id,
              src,
            };
          });

          setImages(currentImages);
        }
      } catch (error) {
        console.error("Error fetching field details", error);
      }
    };

    if (complexId && id) {
      fetchFieldDetails();
    }
  }, [complexId, id, setValue]);

  const handleSubmit = async (values: IFormInput) => {
    const base64Images = await Promise.all(
      images.map((img) => toBase64(img.file))
    ).then((res) => res.filter(x => x));

    const fieldData = {
      name: values.fieldName,
      pricePerHour: parseFloat(values.pricePerHour),
      currencyId: values.currencyId,
      playerSizeId: values.sizeId,
      flooringTypeId: values.floorId,
      width: parseFloat(values.width),
      length: parseFloat(values.length),
      heating,
      lighting,
      tribunes,
      isCovered,
      isUnavailable,
      images: base64Images,
      imagesToRemove,
    };

    try {
      await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/owner/complexes/${complexId}/fields/${id}`,
        fieldData,
        { headers: getHeaders(true) }
      );
      toast.success("Field edited successfully!");
      navigate(`/arena/${complexId}/field-reservation/${id}`);
    } catch (error) {
      console.error("Error updating field", error);
    }
  };

  const handleDeleteField = useCallback(
    async (fieldId?: string) => {
      if (!complexId || !fieldId) {
        toast("There was a problem deleting the field", {
          toastId: "delete-field",
          theme: "dark",
          className: "text-center",
          bodyClassName: "custom-toast-body",
        });
        return;
      }

      const { data, error } = await deleteField(
        parseInt(complexId),
        parseInt(fieldId)
      );

      if (error) {
        toast(error.error.message, {
          toastId: "update-owner",
        });
        return;
      }

      if (data) {
        navigate(`/arena/${complexId}/fields`);
      }
    },
    [complexId, navigate]
  );

  const resetForm = () => {
    reset();
    setCurrency(null);
    setSelectedSize(null);
    setSelectedFloor(null);
    setIsCovered(false);
    setHeating(false);
    setLighting(false);
    setTribunes(false);
    setIsUnavailable(false);
    setImages([]);
    setImagesToRemove([]);
  };

  const handleCancel = () => {
    resetForm();
    navigate(`/arena/${complexId}/field-reservation/${id}`);
  };

  // const handleImageRemoval = (imageUrl: string) => {
  //   setImagesToRemove((prev) => [...prev, imageUrl]);
  //   setExistingImages((prev) => prev.filter((img) => img !== imageUrl));
  // };

  return (
    <>
      <div className="mx-auto mb-4">
        <div className="text-mainWhite">
          <p>Photo(s) of the field</p>
          <Dropzone addImages={addImages} removeImage={removeImage} images={images} />
          {/* <div>
            {existingImages.map((image) => (
              <div key={image} className="relative">
                <img
                  src={image}
                  alt="Existing"
                  className="w-24 h-24 object-cover"
                />
                <button
                  type="button"
                  className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
                  onClick={() => handleImageRemoval(image)}
                >
                  X
                </button>
              </div>
            ))}
          </div> */}
        </div>
        <div className="mt-4">
          <p className="text-mainWhite">
            {t(COMMON_TRANSLATE_KEYS.NAME_OF_THE_FIELD)}{" "}
            <span className="text-functionalRed">*</span>
          </p>
          <InputField
            placeholder={`${t(COMMON_TRANSLATE_KEYS.FIELD)}`}
            width={37}
            error={t(errors.fieldName?.message || '')}
            {...register('fieldName')}
          />
        </div>
        <div className="flex flex-row gap-x-4">
          <div className="mt-4">
            <p className="text-mainWhite">
              {t(COMMON_TRANSLATE_KEYS.PRICE_PER_HOUR)}{" "}
              <span className="text-functionalRed">*</span>
            </p>
            <InputField
              placeholder="40"
              type="text"
              width={18}
              error={t(errors.pricePerHour?.message || '')}
              {...register('pricePerHour')}
            />
          </div>
          <div className="mt-4">
            <p className="text-mainWhite">
              {t(COMMON_TRANSLATE_KEYS.CURRENCY)}{" "}
              <span className="text-functionalRed">*</span>
            </p>
            <CurrencyDropdown
              selectedCurrency={currency}
              setSelectedCurrency={handleCurrencySelect}
              error={t(errors.currencyId?.message || '')}
              showIcon={false}
              width={18}
            />
          </div>
        </div>
        <div className="flex flex-row gap-x-4">
          <div className="mt-4">
            <p className="text-mainWhite">
              {t(COMMON_TRANSLATE_KEYS.FIELD_LENGTH)}{" "}
              <span className="text-functionalRed">*</span>
            </p>
            <InputField
              placeholder={`50 ${t(COMMON_TRANSLATE_KEYS.M)}`}
              type="text"
              width={18}
              error={t(errors.length?.message || '')}
              {...register('length')}
            />
          </div>
          <div className="mt-4">
            <p className="text-mainWhite">
              {t(COMMON_TRANSLATE_KEYS.FIELD_WIDTH)}{" "}
              <span className="text-functionalRed">*</span>
            </p>
            <InputField
              placeholder={`20 ${t(COMMON_TRANSLATE_KEYS.M)}`}
              type="text"
              error={t(errors.width?.message || '')}
              {...register('width')}
              width={18}
            />
          </div>
        </div>

        <div className="mt-4 flex flex-row justify-between">
          <div>
            <p className="text-mainWhite">
              {t(COMMON_TRANSLATE_KEYS.SIZE)}{" "}
              <span className="text-functionalRed">*</span>
            </p>

            <DropdownMenu<PlayerSize>
              items={sizeOptions}
              selectedOption={selectedSize}
              setSelectedOption={handleSizeSelect}
              showIcon={false}
              width={18}
              error={t(errors.sizeId?.message || '')}
            />
          </div>
          <div>
            <p className="text-mainWhite">
              {t(COMMON_TRANSLATE_KEYS.TYPE_OF_FLOORING)}{" "}
              <span className="text-functionalRed">*</span>
            </p>

            <FlooringTypesDropdown
              selectedFlooring={selectedFloor}
              setSelectedFlooring={handleFlooringSelect}
              error={t(errors.floorId?.message || '')}
              showIcon={false}
              width={18}
            />
          </div>
        </div>

        <div className="flex flex-row justify-around gap-4 mt-4">
          <SwitchButton
            width={18}
            isSelected={isCovered}
            onClick={() => setIsCovered(!isCovered)}
          >
            {t(COMMON_TRANSLATE_KEYS.IS_IT_COVERED)}
          </SwitchButton>
          <SwitchButton
            width={18}
            isSelected={lighting}
            onClick={() => setLighting(!lighting)}
          >
            {t(COMMON_TRANSLATE_KEYS.DOES_IT_HAVE_LIGHTING)}
          </SwitchButton>
        </div>
        <div className="flex flex-row justify-around gap-4 mt-4">
          <SwitchButton
            width={18}
            isSelected={heating}
            onClick={() => setHeating(!heating)}
          >
            {t(COMMON_TRANSLATE_KEYS.HEATING)}
          </SwitchButton>
          <SwitchButton
            width={18}
            isSelected={tribunes}
            onClick={() => setTribunes(!tribunes)}
          >
            {t(COMMON_TRANSLATE_KEYS.TRIBUNES)}
          </SwitchButton>
        </div>

        <Divider mb={4} mt={4} />

        <div className="flex flex-col gap-5">
          <SwitchButton
            width={37}
            isSelected={isUnavailable}
            onClick={() => setIsUnavailable(!isUnavailable)}
            style={{
              backgroundColor: "transparent",
              paddingLeft: 0,
            }}
          >
            {t(COMMON_TRANSLATE_KEYS.UNAVAILABLE_FIELD)}
          </SwitchButton>

          <div className="flex flex-row justify-between items-center">
            <p className="text-white">
              {t(COMMON_TRANSLATE_KEYS.DELETE_THIS_FIELD)}
            </p>
            <Button
              IconAfter={TrashIcon}
              width={9}
              className="rounded-xl"
              textColor="text-white"
              backgroundColor="bg-additionalBlack"
              onClick={() => openModal()}
            >
              {t(COMMON_TRANSLATE_KEYS.DELETE)}
            </Button>
          </div>
        </div>

        <Divider mb={4} mt={8} />

        <div className="flex flex-row justify-around mt-8">
          <Button
            textColor="text-mainWhite"
            backgroundColor="bg-functionalRed"
            width={18}
            onClick={() => handleCancel()}
          >
            {t(COMMON_TRANSLATE_KEYS.CANCEL)}
          </Button>
          <Button
            textColor="text-black-500"
            backgroundColor="bg-functionalGreen"
            width={18}
            onClick={handleFormSubmit(handleSubmit)}
          >
            {t(COMMON_TRANSLATE_KEYS.UPDATE)}
          </Button>
        </div>
      </div>

      <Dialog
        open={isOpen}
        onClose={closeModal}
        className="fixed inset-0 flex items-center justify-center z-50"
      >
        <Dialog.Overlay className="fixed inset-0 bg-black  opacity-50" />
        <div className="bg-gray-900 w-[344px] p-6 rounded-md absolute top-212 left-468">
          <Dialog.Title>
            <div className="flex flex-row justify-around text-white font-bold mb-6">
              <span>{`${t(COMMON_TRANSLATE_KEYS.DELETE_SURE)} ${getValues('fieldName')} ?`}</span>
              <button onClick={closeModal}>X</button>
            </div>
          </Dialog.Title>

          <div className="flex flex-row gap-8">
            <Button
              onClick={async () => await handleDeleteField(id)}
              type="button"
              backgroundColor="bg-cancel"
              textColor="text-white"
              width={16}
              height={3}
            >
              {t(COMMON_TRANSLATE_KEYS.YES)}
            </Button>
            <Button
              onClick={() => closeModal()}
              type="button"
              backgroundColor="bg-transparent"
              border="border"
              borderColor="border-mainBlue"
              textColor="text-mainBlue"
              width={16}
              height={3}
            >
              {t(COMMON_TRANSLATE_KEYS.NO)}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default EditFieldInfo;
