import axios, { AxiosError } from "axios";
import { getHeaders } from "./utils/getHeader";
import { GetNotificationsResult, RegisterDeviceResult, SetRetrievedNotificationsResult } from "../types/notifications";

type RegisterDeviceResultWithError = {
  data?: RegisterDeviceResult;
  error?: any;
}

type GetNotificationsResultWithError = {
  data?: GetNotificationsResult;
  error?: any;
}

type GetPendingNotificationsResultWithError = {
  data?: number;
  error?: any;
}

type SetRetrievedNotificationsResultWithError = {
  data?: SetRetrievedNotificationsResult;
  error?: any;
}

export const subscribeForNotifications = async (firebaseToken: string): Promise<RegisterDeviceResultWithError> => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/notifications/register`;

  let result: RegisterDeviceResultWithError = {};

  const payload = {
    token: firebaseToken,
  };

  try {
    const response = await axios.put(
      url,
      payload,
      {
        headers: getHeaders(true),
      },
    );

    result.data = response.data as RegisterDeviceResult;
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error calling: ", url);
    result.error = response?.data;
  }

  return result;
}

export const fetchNotifications = async (page = 1, limit = 10): Promise<GetNotificationsResultWithError> => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/notifications?page=${page}&limit=${limit}`;

  let result: GetNotificationsResultWithError = {};
  
  try {
    const response = await axios.get(
      url,
      {
        headers: getHeaders(true),
      }
    );
  
    result.data = response.data as GetNotificationsResult;
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error fetching user notifications:", error);
    result.error = response?.data;
  }
  
  return result;
}

export const fetchUnreadNotifications = async (): Promise<GetPendingNotificationsResultWithError> => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/notifications?page=${1}&limit=${1}&status=pending`;

  let result: GetPendingNotificationsResultWithError = {};
  
  try {
    const response = await axios.get(
      url,
      {
        headers: getHeaders(true),
      }
    );
  
    result.data = (response.data as GetNotificationsResult).totalCount;
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error fetching pending notifications:", error);
    result.error = response?.data;
  }
  
  return result;
}

export const markAsReadNotifications = async (notificationIds?: number[]): Promise<SetRetrievedNotificationsResultWithError> => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/notifications/received`;

  let result: SetRetrievedNotificationsResultWithError = {};

  const payload = {
    notificationIds,
  };
  
  try {
    const response = await axios.put(
      url,
      payload,
      {
        headers: getHeaders(true),
      }
    );
  
    result.data = response.data as SetRetrievedNotificationsResult;
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error setting notifications as retrieved:", error);
    result.error = response?.data;
  }
  
  return result;
}