import { Reservation } from "./reservations";

export interface MenuItem<T> {
  label: string | React.ReactNode;
  value: T;
  onClick?: Function;
  isDisabled?: boolean;
  className?: string;
}

export interface Complex {
  label: string;
  id: number;
  name: string;
  country: string;
  city: string;
  address: string;
  district: string;
  phone: string;
  imageUrls: Array<string>;
  openingHours: string;
  closingHours: string;
  description: string;
  parking: boolean;
  dressing: boolean;
  showers: boolean;
  coffee: boolean;
  providesBalls: boolean;
  providesTShirts: boolean;
  fieldsCount: number;
}

export interface PlayerSize {
  id: number;
  label: string;
  size: string;
}

export interface FlooringType {
  id: number;
  name: string;
  nameBg?: string;
}

export interface Currency {
  id: number;
  name: string;
  nameBg?: string;
}

export enum ReservationStatuses {
  Pending = "PENDING",
  Accepted = "CONFIRMED",
  Declined = "DECLINED",
}

export enum Months {
  January = "January",
  February = "February",
  March = "March",
  April = "April",
  May = "May",
  June = "June",
  July = "July",
  August = "August",
  September = "September",
  October = "October",
  November = "November",
  December = "December",
}

export interface Position {
  id: number;
  name: string;
}

export interface Player {
  id: number;
  name: string;
  imageUrl: string;
  bio: string;
  city: string;
  email: string;
  phone: string;
  age: number;
  nickname: string;
  position: Position;
}

export interface Team {
  id: number;
  name: string;
  city: string;
  logoUrl: string;
  info: string;
  players: Player[];
  captain: Player;
}

export interface Creator {
  id: number;
  name: string;
  city: string;
  email: string;
  phone: string;
  age: number;
  nickname: string;
  position: Position;
}

export interface Field {
  imageUrls: string[];  
  id: number;
  name: string;
  description: string;
  pricePerHour: number;
  reservations: Reservation[];
  city: string;
  country: string;
  address: string;
  complexName: string;
  complexId: number;
  playerSize: PlayerSize;
  flooringType: FlooringType;
  heating: boolean;
  lighting: boolean;
  dressingRooms: boolean;
  shower: boolean;
  isCovered: boolean;
  tribunes: boolean;
  currency: string;
  team?: Team;
  isUnavailable: boolean;
}

export type PaginatedResult = {
  page: number;
  pageCount: number;
  totalCount: number;
  totalPages: number;
  hasNext: boolean;
  hasPrev: boolean;
}

export interface DropdownMenuProps<T> {
  items: Array<MenuItem<T>>;
  selectedOption: MenuItem<T> | null;
  setSelectedOption: Function;
  showIcon?: boolean;
  width?: number;
  height?: number;
  placeholder?: string;
  transparent?: boolean;
  customLabel?: string; 
  isIconAdjacent?: boolean;
  isIconActive?: boolean;
  error?: string;
}

export type TImage = {
  id: string;
  src: string;
  file?: File | null;
}