import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ReservationCard from "../components/cards/ReservationCard";
import Button from "../components/buttons/Button";
import ComplexCard from "../components/cards/ComplexCard";
import { getToken } from "../utils/token";
import { Reservation } from "../types/reservations";
import { Field } from "../types";
import { useAuth } from "../hooks/useAuth";
import OwnerBar from "../components/OwnerBar";
import { useComplexes } from "../hooks/useComplexes";
import { useTranslation } from "react-i18next";
import { COMMON_TRANSLATE_KEYS } from "../constants/translate-keys";
import FieldCard from "../components/cards/FieldCard";

const ArenaFieldReservation: React.FC = () => {
  const { id, fieldId } = useParams<{ id: string; fieldId: string }>();

  const { t } = useTranslation();

  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [field, setField] = useState<Field | null>(null);

  const navigate = useNavigate();

  const { user } = useAuth();

  const { complexes } = useComplexes();

  const complex = useMemo(() => {
    return complexes.find((c) => c.id === Number(id)) || null;
  }, [complexes, id]);

  const fetchFieldWithReservations = useCallback(async () => {
    try {
      const response = await axios.get<Field>(
        `${process.env.REACT_APP_API_ENDPOINT}/owner/complexes/${id}/fields/${fieldId}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Accept-Language": "en",
          },
          params: {
            excludeExpired: true,
          },
        }
      );

      setField(response.data);
      setReservations(response.data.reservations);
    } catch (error) {
      console.error("Error fetching field with reservations:", error);
    }
  }, [id, fieldId]);

  useEffect(() => {
    fetchFieldWithReservations();
  }, [fetchFieldWithReservations]);

  if (!field) {
    return (
      <div className="text-mainWhite">{t(COMMON_TRANSLATE_KEYS.LOADING)}</div>
    );
  }
  const renderFeature = (
    label: string,
    feature: boolean | string,
    t: (key: string) => string
  ) => {
    let featureValue: string;

    if (typeof feature === "boolean") {
      featureValue = feature
        ? t(COMMON_TRANSLATE_KEYS.YES)
        : t(COMMON_TRANSLATE_KEYS.NO);
    } else {
      featureValue = feature as string;
    }

    return (
      <div className="flex flex-row justify-between my-2">
        <p className="text-textGray">{t(label)}:</p>
        <p>{featureValue}</p>
      </div>
    );
  };

  return (
    <div className="text-mainWhite mx-[30px]">
      <h1 className="text-white my-4">
        <Link to="/my-fields" className="pointer text-gray-500">
          {t(COMMON_TRANSLATE_KEYS.COMPLEXES)} /
        </Link>
        <Link
          to={`/arena/${field.complexId}/info`}
          className="pointer text-gray-500"
        >
          {field.complexName} ( {t(COMMON_TRANSLATE_KEYS.FIELDS)} )
        </Link>
        / {field.name} ({t(COMMON_TRANSLATE_KEYS.INFO)})
      </h1>
      <FieldCard
        field={field}
        title={field.name}
        location={`${field.city}, ${field.country} | ${field.address}`}
        width="xl"
      >
        <div className="flex flex-row justify-end gap-4 items-center">
          <span className="text-end mx-6">
            <p className="text-sm text-textGray">
              {t(COMMON_TRANSLATE_KEYS.PRICE_PER_HOUR)}
            </p>
            <p className="text-base text-white">
              {field.pricePerHour.toFixed(2)} {field.currency}
            </p>
          </span>
          <Button
            backgroundColor="bg-functionalGreen"
            width={12}
            textColor="text-gray-800"
            onClick={() =>
              navigate(`/book-field/${field.complexId}/${field.id}`)
            }
            isDisabled={field.isUnavailable}
          >
            {t(COMMON_TRANSLATE_KEYS.BOOK_FIELD)}
          </Button>
          <Button
            border="border-mainBlue"
            backgroundColor="bg-gray-800"
            width={12}
            textColor="text-mainBlue"
            onClick={() => navigate(`/edit-field-info/${id}/${fieldId}`)}
          >
            {t(COMMON_TRANSLATE_KEYS.EDIT_INFO)}
          </Button>
        </div>
      </FieldCard>
      <div className="flex flex-row gap-8 mt-6">
        <div className="w-2/3">
          <OwnerBar ownerText={t(COMMON_TRANSLATE_KEYS.OWNER)} />

          <div className="flex flex-row justify-between mb-4">
            <p>{` ${t(COMMON_TRANSLATE_KEYS.RESERVATIONS)} (${reservations.length})`}</p>
            <button className="text-mainBlue font-medium">
              <Link
                to={`/arena/${field.complexId}/field/${field.id}/calendar`}
                state={{
                  complexName: field.complexName,
                  fieldName: field.name,
                }}
              >
                {t(COMMON_TRANSLATE_KEYS.VIEW_CALENDAR)}
              </Link>
            </button>
          </div>
          <div className="flex flex-col col-span-1 justify-between gap-y-4">
            {reservations.map((reservation) => (
              <ReservationCard
                key={reservation.id}
                fetchReservations={fetchFieldWithReservations}
                reservation={reservation}
                field={field}
              />
            ))}
          </div>
        </div>

        <div className="w-1/3 text-mainWhite mb-12">
          <div>
            <h2 className="text-mainWhite">
              {" "}
              {t(COMMON_TRANSLATE_KEYS.CONTACT_OWNER)}
            </h2>
            <div className="flex flex-row justify-between my-2">
              <p className="text-textGray">
                {" "}
                {t(COMMON_TRANSLATE_KEYS.EMAIL)} :
              </p>
              <p>{user?.email}</p>
            </div>
            <div className="flex flex-row justify-between my-2">
              <p className="text-textGray">{t(COMMON_TRANSLATE_KEYS.PHONE)}:</p>{" "}
              <p>{complex?.phone}</p>
            </div>
          </div>
          <div className="mt-4">
            <h2 className="text-mainWhite">
              {" "}
              {t(COMMON_TRANSLATE_KEYS.FIELD_INFO)}
            </h2>
            <div className="mt-4">
              {renderFeature(
                COMMON_TRANSLATE_KEYS.SIZE,
                field.playerSize.label,
                t
              )}
              {renderFeature(
                COMMON_TRANSLATE_KEYS.TYPE_OF_FLOORING,
                field.flooringType.name,
                t
              )}
              {renderFeature(
                COMMON_TRANSLATE_KEYS.IS_IT_COVERED,
                field.isCovered,
                t
              )}
              {renderFeature(
                COMMON_TRANSLATE_KEYS.DOES_IT_HAVE_LIGHTING,
                field.lighting,
                t
              )}
              {renderFeature(COMMON_TRANSLATE_KEYS.HEATING, field.heating, t)}
              {renderFeature(COMMON_TRANSLATE_KEYS.TRIBUNES, field.tribunes, t)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArenaFieldReservation;
