import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ROUTE_LOGIN, ROUTE_MY_FIELDS } from '../router/constants';
import { useAuth } from '../hooks/useAuth';
import { getOwnerDetails } from '../api/owner';
import { User } from '../types/auth';

const invalidTokenRoute = `${ROUTE_LOGIN}?error=Error occurred. Please contact the developer`;

const GoogleRedirectPage = () => {
  const { login } = useAuth();

  const navigate = useNavigate();

  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');

  const navigateWithReplace = useCallback((route: string) => {
    navigate(route, { replace: true });
  }, [navigate]);

  const setTokenAndNavigate = useCallback(async () => {
    if (!token) {
      navigateWithReplace(invalidTokenRoute);
      return;
    }

    const { data, error } = await getOwnerDetails(
      token,
    );

    if (data) {
      await login(token, data as User, true);
      return;
    }

    navigateWithReplace(`${ROUTE_LOGIN}?error=${error?.error}`);
  }, [token, navigateWithReplace, login]);

  useEffect(() => {
    setTokenAndNavigate();
  }, [setTokenAndNavigate]);

  return (
    <></>
  );
};

export default GoogleRedirectPage;