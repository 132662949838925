import { createContext, ReactNode, useCallback, useMemo } from "react";
import { getToken } from "firebase/messaging";

import { messaging } from "../services/firebase";
import { subscribeForNotifications } from "../api/notifications";
import { setMsgToken } from "../utils/token";

type TProps = {
  children: ReactNode;
};

type FirebaseMessagingContextValue = {
  initializeNotifications: () => Promise<void>;
};

export const FirebaseMessagingContext = createContext<FirebaseMessagingContextValue>({
  initializeNotifications: async () => undefined,
});

const FirebaseMessagingProvider = ({ children }: TProps) => {
  const initializeNotifications = useCallback(async () => {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: 'BLqeBXCg0gjA16v6Oh02C2X1VKhDHA_fXM_zYuKPY4tb0OQ4OxjDXVHa545A1ahxJS491cOzChLn5NWSdBT2hRI',
      });

      const { data, error } = await subscribeForNotifications(token);

      if (error) {
        console.log('Error subscribing for notifications: ', error);
        return;
      }

      if (data?.success) {
        console.log('Success subscribing for notifications: ');
        setMsgToken(token);
      }      
    } else if (permission === "denied") {
      alert("You denied permission for notifications");
    }
  }, []);

  const value = useMemo(
    () => ({
      initializeNotifications,
    }),
    [initializeNotifications]
  );

  return <FirebaseMessagingContext.Provider value={value}>{children}</FirebaseMessagingContext.Provider>;
};

export default FirebaseMessagingProvider;
