import React, { ReactNode } from "react";
import AuthProvider from "./AuthContext";
import ComplexesProvider from "./ComplexesContext";
import AvailableReservationsProvider from "./AvailableReservationSlotsContext";
import FirebaseMessagingProvider from "./FirebaseMessagingContext";
import NotificationsProvider from "./NotificationsContext";
import UnreadNotificationsProvider from "./UnreadNotificationsContext";
import CurrenciesProvider from "./CurrenciesContext";
import FlooringTypesProvider from "./FlooringTypesContext";

interface TProps {
  children: ReactNode;
}

// Add new contexts here
const ContextsWrapper = ({ children }: TProps) => (
  <FirebaseMessagingProvider>
    <AuthProvider>
      <UnreadNotificationsProvider>
        <NotificationsProvider>
          <ComplexesProvider>
            <AvailableReservationsProvider>
              <CurrenciesProvider>
                <FlooringTypesProvider>
                  {children}
                </FlooringTypesProvider>
              </CurrenciesProvider>
            </AvailableReservationsProvider>
          </ComplexesProvider>
        </NotificationsProvider>
      </UnreadNotificationsProvider>
    </AuthProvider>
  </FirebaseMessagingProvider>
);

export default ContextsWrapper;
