import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { Player } from "../../types/players";
import { Reservation } from "../../types/reservations";
import MyDrawer from "../Drawer";
import StatusBadge from "../StatusBadge";
import { DATE_FORMATS, getDateForCalendar } from "../../utils/dates";
import PlayerImage from "../PlayerImage";
import { getPlayerRole } from "../../utils/players";
import { HOURS_MINUTES_REGEX } from "../../utils/regex";
import { COMMON_TRANSLATE_KEYS } from "../../constants/translate-keys";

import TeamLogo from "../../icons/images/team.png";
import NoTeamLogo from "../../icons/images/no-team.png";
import LocationIcon from "../../icons/svg/location.svg";
import PlayerIcon from "../../icons/svg/player.svg";
import ClockIcon from "../../icons/svg/clock.svg";

type TProps = {
  isOpen: boolean;
  reservation: Reservation | null;
  onPlayerClick: (player: Player) => void;
  closeDrawer: () => void;
};

const chevronClassName = "size-4 mx-1";

const ReservationDetailsModal = ({
  isOpen,
  reservation,
  closeDrawer,
  onPlayerClick,
}: TProps) => {
  const [showMore, setShowMore] = useState(false);

  const { t, i18n } = useTranslation();

  const getPlayTime = (start: string, end: string) => {
    if (start.match(HOURS_MINUTES_REGEX) && end.match(HOURS_MINUTES_REGEX)) {
      return `${start} - ${end}`;
    }

    return `${dayjs(getDateForCalendar(start)).format(DATE_FORMATS.HOURS_MINUES)} - ${dayjs(getDateForCalendar(end)).format(
      DATE_FORMATS.HOURS_MINUES
    )}`;
  }

  const renderModalContent = useCallback(() => {
    if (!reservation) {
      return <></>;
    }  
    const {
      team,
      field,
      date,
      start,
      end,
      status,
      isGamePublic,
      creator,
      otherName,
      otherPhone,
    } = reservation;

    if (team) {
      return (
        <div className="h-full overflow-y-auto">
          <div className="flex justify-between">
            <StatusBadge status={status} className="m-0 ml-0 mb-4" />
            <button onClick={closeDrawer} className="text-white">
              X
            </button>
          </div>
          <div className="bg-additionalBlack rounded-lg">
            <div className="flex">
              <img src={team.logoUrl || TeamLogo} alt="Team" className="object-cover w-full h-[240px] rounded-lg" />
            </div>
            <div className="flex flex-col p-3 gap-2">
              <h2 className="font-inter text-2xl not-italic font-bold leading-7">
                {team.name}
              </h2>
              <div>
                <div className="flex flex-row items-start gap-2">
                  <img src={LocationIcon} alt="Location" />
                  <span>
                    <p>{[field.country, field.city, field.district].filter(x => x).join(', ')}</p>
                    <p className="text-midGray">{`${field.name} (${field.address})`}</p>
                  </span>
                </div>
              </div>
              <div className="flex flex-row items-start gap-2">
                <img src={PlayerIcon} alt="Players" />
                <p>{`${field.playerSize.size} ${t(COMMON_TRANSLATE_KEYS.PLAYERS, { count: Number(field.playerSize.size) })}`}</p>
              </div>
              <div className="flex flex-row items-start gap-2">
                <img src={ClockIcon} alt="Clock" />
                <p>{`${date ? dayjs(date).locale(i18n.language).format(DATE_FORMATS.WEEK_FIRST) : t(COMMON_TRANSLATE_KEYS.RECURRING)} (${getPlayTime(start, end)})`}</p>
              </div>
              <div className="flex flex-row">
                <p className="text-mainBlue">{t(COMMON_TRANSLATE_KEYS.OWNER)}/{t(COMMON_TRANSLATE_KEYS.CAPTAIN)}: <span className="text-white">{team.captain.name}</span></p>
              </div>
              {team.secondCaptain && (
                <div className="flex flex-row">
                  <p className="text-mainBlue">{t(COMMON_TRANSLATE_KEYS.SECOND_CAPTAIN)}: <span className="text-white">{team.secondCaptain.name}</span></p>
                </div>
              )}
              <div className="mt-4 mb-8">
                <div className="flex flex-row justify-between">
                  <p className="text-lg font-semibold">{t(COMMON_TRANSLATE_KEYS.PLAYERS, { count: 2 })}</p>
                  <button
                    className={`inline-flex items-center ${team.players.length < 5 && 'hidden'}`}
                    disabled={team.players.length < 5}
                    onClick={() => {
                      console.log('show more clicked')
                      setShowMore((prev) => !prev)}}
                  >
                    {t(COMMON_TRANSLATE_KEYS.SEE_MORE)} {showMore ? <ChevronDownIcon className={chevronClassName} /> : <ChevronUpIcon className={chevronClassName} />}</button>
                </div>
                <div
                  className="grid grid-cols-4 gap-4 mt-4"
                >
                  {team.players.map((player, index) => (
                    <PlayerImage
                      key={index}
                      name={player.name}
                      role={getPlayerRole(player, team)}
                      imageUrl={player.imageUrl}
                      onClick={() => onPlayerClick(player)}
                      hidden={!showMore && index > 3}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    const otherPersonNameAndPhone = otherName && otherPhone ? `${otherName} (${otherPhone})` : '';
    
    return (
      <div className="h-full overflow-y-auto">
        <div className="flex justify-between">
          <StatusBadge status={status} className="m-0 ml-0 mb-4" />
          <button onClick={closeDrawer} className="text-white">
            X
          </button>
        </div>
        <div className="bg-additionalBlack rounded-lg">
          <div className="flex">
            <img src={NoTeamLogo} alt="Team" className="object-cover w-full h-[240px]" />
          </div>
          <div className="flex flex-col p-3 gap-2">
            <h2 className="font-inter text-xl not-italic font-bold leading-7">
              {isGamePublic ? t(COMMON_TRANSLATE_KEYS.PUBLIC_GAME) : t(COMMON_TRANSLATE_KEYS.PRIVATE_GAME)}
            </h2>
            <div>
              <div className="flex flex-row items-start gap-2">
                <img src={LocationIcon} alt="Location" />
                <span>
                  <p>{[field.country, field.city, field.district].filter(x => x).join(', ')}</p>
                  <p className="text-midGray">{`${field.name} (${field.address})`}</p>
                </span>
              </div>
            </div>
            <div className="flex flex-row items-start gap-2">
              <img src={PlayerIcon} alt="Players" />
              <p>{`${field.playerSize.size} ${t(COMMON_TRANSLATE_KEYS.PLAYERS, { count: Number(field.playerSize.size) })}`}</p>
            </div>
            <div className="flex flex-row items-start gap-2">
              <img src={ClockIcon} alt="Clock" />
              <p>{`${date ? dayjs(date).locale(i18n.language).format(DATE_FORMATS.WEEK_FIRST) : t(COMMON_TRANSLATE_KEYS.RECURRING)} (${getPlayTime(start, end)})`}</p>
            </div>
            <div className="flex flex-row">
              <p className="text-mainBlue">{t(COMMON_TRANSLATE_KEYS.OWNER)}/{t(COMMON_TRANSLATE_KEYS.CAPTAIN)}: <span className="text-white">{otherPersonNameAndPhone || creator.name}</span></p>
            </div>
          </div>
        </div>
      </div>
    )
  }, [reservation, closeDrawer, onPlayerClick, showMore, t, i18n.language]);

  return (
    <>
      <MyDrawer isOpen={isOpen}>
        {renderModalContent()}
      </MyDrawer>
    </>
  )
};

export default ReservationDetailsModal;