import { MouseEventHandler, useCallback, useMemo } from "react";
import { getTimeAgoText } from "../../utils/dates";

type TProps = {
  title: string;
  body: string
  notificationData: any;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const NotificationToast = ({ title, body, notificationData, className, onClick }: TProps) => {
  const containerClass = useMemo(() => {
    let _className = 'flex flex-col';

    if (className) {
      _className += ` ${className}`;
    }

    return _className;
  }, [className]);

  const renderUserImg = useCallback(() => {
    if (notificationData.sender?.imageUrl) {
      const { imageUrl } = notificationData.sender;

      if (imageUrl) {
        return (
          <img
            src={imageUrl}
            alt="profile"
            className="mr-4 rounded-full w-[36px] h-[36px] object-cover"
            width={36}
            height={36}
          />
        );
      }
    }

    return (
      <div className="mr-4 rounded-full w-[36px] h-[36px] border border-gray-400 bg-mainBlack flex-shrink-0"></div>
    );
  }, [notificationData]);

  return (
    <div className={containerClass} onClick={onClick}>
      <div className="flex flex-row">
        {renderUserImg()}
      <div className="flex flex-col text-start">
        <span className="text-white font-bold">{notificationData.sender?.name}</span>
        <p className="text-textGray whitespace-pre-wrap">{title}</p>
        <p className="text-white text-sm whitespace-pre-wrap">{body}</p>
        <p className="text-textGray text-sm whitespace-pre-wrap text-end">{getTimeAgoText(notificationData.createdAt, true)}</p>
      </div>
      </div>
    </div>
  );
};

export default NotificationToast;